import { useEffect, useRef, useState } from 'react';
import set from 'lodash/set';
import getConfig from 'next/config';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

export interface PageMetrics {
  brandCode: string;
  brandName: string;
  ctyhocn?: string;
  language: string;
  primaryCategory: 'Portfolio' | 'Brand' | 'Property';
  trackCallName: string;
  categoryPageDetail?: string;
}

export interface PageMetricsListing extends PageMetrics {
  offerId?: number;
  offerName?: string;
}

export interface PageMetricsDetail extends PageMetrics {
  offerId: string;
  offerName: string;
  explicitPageName?: string;
}

export function Analytics({ metrics }: { metrics: PageMetricsListing | PageMetricsDetail }) {
  const hasSetMetrics = useRef(false);
  const [metricsReady, setMetricsReady] = useState(
    typeof window !== 'undefined' && !!window._satellite?.track
  );
  const scriptSrc = publicRuntimeConfig.TRACKING_SEED_FILE;

  useEffect(() => {
    if (!hasSetMetrics.current && metricsReady && metrics) {
      const {
        brandCode,
        brandName,
        ctyhocn,
        language,
        offerId,
        offerName,
        primaryCategory,
        trackCallName,
        categoryPageDetail,
      } = metrics;
      hasSetMetrics.current = true;
      setDefaultData();
      if ((metrics as PageMetricsDetail).explicitPageName) {
        setDigitalData(
          'page.pageInfo.pageName',
          `Browser:${language.toUpperCase()}:${brandName}:${primaryCategory}:Offers:${
            (metrics as PageMetricsDetail).explicitPageName
          }`
        );
      } else {
        setDigitalData(
          'page.pageInfo.pageName',
          `Browser:${language.toUpperCase()}:${brandName}:${primaryCategory}:Offers${
            offerId ? ':Detail' : ''
          }`
        );
      }

      setDigitalData('page.pageInfo.language', language);
      setDigitalData('page.category.brand', brandCode);
      setDigitalData('page.category.primaryCategory', primaryCategory);
      if (categoryPageDetail) {
        setDigitalData('page.category.pageDetail1', categoryPageDetail);
      }
      setDigitalData('page.category.subSection', `${primaryCategory.toLocaleLowerCase()}:offers`);
      !!offerId && setDigitalData('page.pageInfo.pageDetail1', offerId);
      !!offerId &&
        !!offerName &&
        setDigitalData('page.attributes.offerId', `${offerId}:${offerName}`);
      !!ctyhocn && setDigitalData('product[0].productInfo.productID', ctyhocn);

      addEvent('OffersPageView');
      track(trackCallName);
    }
  }, [metrics, metricsReady]);

  if (scriptSrc) {
    return (
      <Script
        strategy="afterInteractive"
        src={scriptSrc}
        onLoad={() => {
          setMetricsReady(true);
        }}
      />
    );
  }

  return null;
}

function setDefaultData() {
  const appVersion = publicRuntimeConfig.APP_VER;
  window.digitalData = {
    event: [],
    page: {
      attributes: {
        expType: 'New Hilton',
        siteExperience: 'R',
        ...(!!appVersion && { version: appVersion }),
      },
      category: {
        exp: 'Browser',
        siteType: 'B',
      },
      pageInfo: {
        destinationURL: window.location.href,
        pageType: 'Offers',
      },
    },
  };
}

function setDigitalData(path: string, value: unknown) {
  window?.digitalData && set(window.digitalData, path, value);
}

function track(name: string) {
  window?._satellite?.track?.(name);
}

function addEvent(name: string) {
  window?.digitalData?.event?.push({ eventInfo: { eventAction: name } });
}
