import type * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCycle } from '@dx-ui/osc-cycle';
import Icon from '@dx-ui/osc-icon';

export type Alert = {
  index?: number;
  status?: 'normal' | 'urgent' | 'emergency';
  title?: string;
  /**
   * Adds truncation to text if true, true is default state (current)
   */
  isTruncated?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Displays a message with an icon if status requires it
 */
export const Alert: React.FC<React.PropsWithChildren<Alert>> = ({
  className,
  status = 'urgent',
  title,
  index = 0,
  isTruncated = true,
  children,
  ...rest
}) => {
  const [t] = useTranslation('osc-alert');
  const { active, total, hasSlides } = useCycle();
  const cycleProps: React.HTMLAttributes<HTMLDivElement> = hasSlides
    ? {
        role: 'group',
        'aria-roledescription': 'slide',
        'aria-label': `${index + 1} of ${total}`,
      }
    : {};

  return (
    <div
      className={cx(
        'w-full pt-2 pb-8 sm:pt-4 md:pb-4',
        {
          'bg-bg-alt': status === 'normal',
          'bg-warn-alt': status === 'urgent',
          'bg-danger-alt': status === 'emergency',
          hidden: hasSlides && index !== active,
        },
        className
      )}
      {...cycleProps}
      {...rest}
    >
      <div className="container flex items-center space-x-3 md:justify-center">
        {status === 'urgent' && (
          <>
            <p className="sr-only">{t('urgent')}</p>
            <Icon name="alert-triangle" size="sm" className="text-warn" variant="solid" />
          </>
        )}
        {status === 'emergency' && (
          <>
            <p className="sr-only">{t('emergency')}</p>
            <Icon name="alert-circle" size="sm" className="text-danger" variant="solid" />
          </>
        )}
        {!!title && (
          <p
            data-osc-product="alert-label"
            className={cx('max-w-3/5 font-bold sm:text-lg brand-lx:font-normal', {
              truncate: isTruncated,
            })}
          >
            {title}
          </p>
        )}
        {children}
      </div>
    </div>
  );
};

export default Alert;
