import cx from 'classnames';
import type * as React from 'react';

// todo: move these into other components
export function Pill({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <span
      className={cx(
        className,
        'bg-bg-disabled inline-block rounded-full px-3 py-2 text-xs font-bold'
      )}
    >
      {children}
    </span>
  );
}
